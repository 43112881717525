<template>
<div>
    <div class="row page-title align-items-center">
        <div class="col-sm-12 col-xl-12">
            <h4 class="mb-1 mt-0">Current Events</h4>

        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-lg-6 col-xl-3" v-for="(event, index) in advertisements" :key="index">
            <b-card no-body :img-src="event.fileDisplayUrl" img-alt="Card image" img-top>
                <b-card-body>
                    <b-card-title>
                        <h5 class="card-title font-size-18"> {{ event.title }}</h5>
                    </b-card-title>
                    <b-card-text>{{ event.description | str_limit(80) }}</b-card-text>
                </b-card-body>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item border-bottom border-top"><b>Location:</b> {{ event.locationName }}</li>
                    <li class="list-group-item border-bottom border-top"><b>Event:</b> {{ event.eventDateText }}</li>
                    <li class="list-group-item border-bottom border-top"><b>Advertisement Stops:</b> {{ event.advertiseEndDate | formatLongDate }}</li>
                </ul>
                <div class="card-body">
                    <b-button @click="editEvent(event.id)" class="mr-2" variant="primary" disabled><i class="uil uil-image-edit mr-2"></i> Edit</b-button>
                    <b-button @click="viewEvent(event.id)" href="javascript:void(0);" class="mr-2" variant="secondary"><i class="uil uil-analytics mr-2"></i> Analytics</b-button>
                    <b-button @click="deleteEvent(event.id)" href="javascript:void(0);" class="mr-2" variant="danger" disabled>Delete</b-button>
                </div>
            </b-card>
        </div>
    </div>
    <div class="account-pages my-5" v-if="advertisements.length<=0">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-4 col-lg-5 col-8">
                    <div class="text-center">
                        <div>
                            <img src="@assets/images/not-found.png" alt class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 text-center">
                    <h3 class="mt-3">No Events Found</h3>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    advertisementData
} from '@state/api/advertisement'

export default {
    page: {
        title: 'Pending Approvals',
        meta: [{
            name: 'description',
        }],
    },
    data() {
        return {
            title: 'Pending Approvals',
            advertisements: [],
            animate: true
        }
    },
    async created() {
        await this.loadAdvertisements();
    },
    methods: {
        async loadAdvertisements() {
            this.advertisements = await advertisementData.searchAll();
        },
        editEvent(id) {
            this.$router.push({
                name: 'EditEvent',
                params: {
                    id: id
                }
            });
        },
        async deleteEvent(id) {
            if (confirm("are you sure to delete this record? this cannot be undone.")) {
                let data = await advertisementData.DeleteAdvertisement(id);

                this.$vs.notify({
                    title: "Success",
                    text: "Event Deleted",
                    color: "success",
                    position: "top-center"
                });
                await this.loadAdvertisements();
            }
        },
        viewEvent(id) {
            this.$vs.notify({
                title: "Success",
                text: "Work in progress",
                color: "success",
                position: "top-center"
            });
            // this.$router.push({
            //     name: 'viewEvent',
            //     params: {
            //         id: id
            //     }
            // });
        }
    },
}
</script>

<style scoped>
.card-img,
.card-img-top,
.card-img-bottom {
    max-height: 230px;
}
</style>
